import React from "react"

import Layout from "./../layouts/Layout/Layout"
import TwoColumnSection from "../sections/TwoColumnSection/TwoColumnSection"
import FeaturesList from "../sections/FeaturesList/FeaturesList"
import HeroSubpage from "../sections/HeroSubpage/HeroSubpage"

import Background from "../assets/images/hero_student.jpg"

import IconMentoring from "../assets/svg/icon_mentoring.svg"
import IconScholarship from "../assets/svg/icon_scholarship.svg"
import IconSkills from "../assets/svg/icon_skills.svg"
import IconChart from "../assets/svg/icon_chart.svg"
import IconCompetencies from "../assets/svg/icon_competencies.svg"
import IconGroup from "../assets/svg/icon_group.svg"
import IconFactory from "../assets/svg/icon_factory.svg"
import IconTravelCard from "../assets/svg/icon_travelCard.svg"
import Faq from "../sections/Faq/Faq"

const StudentPage = () => (
  <Layout>
    <HeroSubpage title="Dla ucznia" backgroundImage={Background} />
    <TwoColumnSection
      heading="Wielki krok na drodze do zawodowej przyszłości"
      article="<h3>Chcesz już dziś zaplanować swoją karierę i zdobyć unikalne kompetencje zawodowe?</h3><p>Program Alkantara to inicjatywa, która łączy naukę w obszarze szeroko pojętego biznesu z możliwością korzystania z finansowego wsparcia. Jako uczestnik Akademii Alkantara bierzesz udział w angażujących zajęciach z ekspertami, korzystasz ze wsparcia dedykowanego mentora i możesz podjąć płatna praktyka zawodowa w jednej z naszych partnerskich firm. </p>"
      buttonText="Więcej o Akademii"
      buttonLink="/akademia"
      equalSections
    />
    <TwoColumnSection
      heading="Do kogo kierujemy ofertę?"
      article={
        "<p>Do udziału w Programie Alkantara zapraszamy zdolne osoby, które chcą studiować na Trójmiejskich uczelniach. Jeśli jesteś w klasie maturalnej, masz świetne wyniki i marzysz o studiach w Gdyni, Gdańsku lub Sopocie – weź udział w rekrutacji na rok akademicki 2021/2022.</p>"
      }
      equalSections
    />
    <FeaturesList
      title="Co zyskujesz przez udział w Programie Alkantara?"
      items={[
        {
          backgroundColor: "#5346A4",
          svg: IconSkills,
          text: "zdobywasz unikalną wiedzę",
        },
        {
          backgroundColor: "#5346A4",
          svg: IconCompetencies,
          text: "budujesz cenne kompetencje zawodowe",
        },
        {
          backgroundColor: "#5346A4",
          svg: IconGroup,
          text: "współpracujesz z biznesowymi ekspertami ",
        },
        {
          backgroundColor: "#5346A4",
          svg: IconChart,
          text: "odkrywasz mechanizmy funkcjonowania biznesu ",
        },
        {
          backgroundColor: "#5346A4",
          svg: IconFactory,
          text: "poznajesz działanie firm od podszewki",
        },
        {
          backgroundColor: "#5346A4",
          svg: IconMentoring,
          text: "korzystasz ze wsparcia profesjonalnego mentora",
        },
        {
          backgroundColor: "#5346A4",
          svg: IconScholarship,
          text: "możesz uzyskać stypendium na czas studiów",
        },
        {
          backgroundColor: "#5346A4",
          svg: IconTravelCard,
          text:
            "możesz podjąć płatną praktykę zawodową w wybranej firmie partnerskiej",
        },
      ]}
    />
    <TwoColumnSection
      heading="Martwisz się o środki na studia w Trójmieście? Zgłoś się do Funduszu Stypendialnego"
      article={
        "<p>Fundusz Stypendialny Alkantara uruchomiliśmy z myślą o zdolnych osobach, które z różnych przyczyn nie mogą podjąć wymarzonych studiów w Gdańsku, Gdyni czy w Sopocie. Stypendium pomaga zorganizować życie w nowym miejscu i stworzyć odpowiednie warunki do nauki i rozwoju. </p>"
      }
      buttonText="Więcej o Funduszu"
      buttonLink="/fundusz"
      equalSections
      noBorder
    />
    <Faq
      lastChild
      faqItems={[
        {
          question: "Co zyskuję dzięki udziałowi w Programie Alkantara?",
          answer:
            "<p>Dzięki udziałowi w Programie Alkantara masz dostęp do cennej wiedzy zawodowej i otrzymujesz kompleksowe wsparcie osób, które osiągnęły sukces w biznesie. Jako uczestnik Programu Alkantara:</p>" +
            "<ul>" +
            "<li>zdobywasz cenne kompetencje biznesowe</li>" +
            "<li>korzystasz z indywidualnej opieki mentora</li>" +
            "<li>bierzesz udział w profesjonalnych warsztatach</li>" +
            "<li>poznajesz szczegółowe zasady funkcjonowania firm</li>" +
            "<li>współpracujesz z ekspertami ze świata biznesu</li>" +
            "<li>podejmujesz płatną praktykę zawodową</li>" +
            "<li>możesz znaleźć pracę w firmach partnerskich</li>" +
            "<li>możesz otrzymać dopasowane do potrzeb stypendium</li>" +
            "</ul>",
        },
        {
          question:
            "Jakie warunki muszę spełnić, żeby wziąć udział w programie Alkantara?",
          answer:
            "<p>Do udziału w Programie Alkantara zapraszamy wszystkich zdolnych maturzystów, którzy chcą studiować na Trójmiejskich uczelniach. Jeśli wyróżniasz się świetnymi wynikami w nauce i chcesz od pierwszego roku studiów budować swoje zawodowe kompetencje, zgłoś się do Programu Alkantara. By to zrobić, skontaktuj się ze swoim wychowawcą lub doradcą zawodowym w swojej szkole. To osoby, które pomogą Ci wypełnić wszystkie dokumenty potrzebne w procesie rekrutacji. Nabór na rok akademicki 2021/2022 trwa do 12 grudnia 2020.</p>",
        },
        {
          question:
            "Czy zajęcia w Akademii Alkantara mogą kolidować z zajęciami na uczelni?",
          answer:
            "<p>Akademia Alkantara jest programem komplementarnym do edukacji na uczelniach wyższych czyli z zasady ma uzupełniać zajęcia akademickie. Zajęcia i spotkania z mentorami ustalamy zawsze w porozumieniu z naszymi uczestnikami, by wszyscy mogli swobodnie brać w nich udział.</p>",
        },
        {
          question:
            "Jakie są moje zobowiązania w związku z udziałem w Programie Alkantara?",
          answer:
            "<p>Udział w Programie Alkantara jest dobrowolny i nie pociąga żadnych zobowiązań wobec organizatorów. Zakładamy, że każdy uczestnik dołącza do naszej inicjatywy, żeby korzystać ze wsparcia mentorów, rozwijać się zawodowo i realnie zwiększyć swoje szanse na rynku pracy.</p>",
        },
        {
          question:
            "Co się stanie, jeśli nie pogodzę studiów z zajęciami w ramach Akademii Alkantara?",
          answer:
            "<p>Udział w Programie Alkantara jest dobrowolny i nie pociąga żadnych zobowiązań wobec organizatorów. Jeśli zrezygnujesz z zajęć w Akademii Alkantara, nie ponosisz żadnej kary. Jeśli korzystasz z Funduszu Stypendialnego, nie musisz oddawać pobranych wcześniej pieniędzy. Nie jesteś też zobowiązana/ zobowiązany do podjęcia praktyk zawodowych w partnerskich firmach Programu Alkantara.</p>",
        },
        {
          question:
            "Co się stanie, gdy zrezygnuję z udziału w Programie Alkantara?",
          answer:
            "<p>Nie czekają Cię żadne konsekwencje. Rezygnacja z Programu Alkantara, tak jak deklaracja udziału, jest w pełni dobrowolna.</p>",
        },
        {
          question:
            "Czy po zakończeniu Akademii Alkantara otrzymuję dyplom lub inny dokument potwierdzający mój udział?",
          answer:
            "<p>Po zakończeniu (czyli po 5 latach) otrzymujesz dyplom ukończenia Akademii Alkantara, który potwierdza zdobytą wiedzę, cenne kontakty i umiejętności biznesowe. To dokument, który możesz wykorzystać w procesach rekrutacyjnych jako istotną przewagę konkurencyjną nad innymi kandydatami. Z dyplomem Akademii Alkantara zdecydowanie łatwiej zdobędziesz wymarzoną pracę i szybko rozwiniesz karierę.</p>",
        },
      ]}
    />
  </Layout>
)

export default StudentPage
